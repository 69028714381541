import React, { useState } from "react";



const StepDetailSubContainer = ({onClick, dataResult, step, type, isClicked}) => {
    var iconSource;
    var title;
    var selectBg = "";
    if (isClicked) {
        selectBg = "select-grey"
    } else {
        selectBg = ""
    }
    const identifier = type+"_"+step;
    const score = dataResult["score_"+type+"_"+step];
    if (type == 'pollution') {
        iconSource = "/icon/eaux-usees.png";
        title = "Pollution"
    } else {
        iconSource = "/icon/robinet.png";
        title = "Consommation"
    }
    let impactLevel;
    switch (score) {
      case 0:
        impactLevel = "Impact Faible";
        break;
      case 1:
        impactLevel = "Impact Moyen";
        break;
      case 2:
        impactLevel = "Impact Fort";
        break;
      case 3:
        impactLevel = "Impact Critique";
        break;
      default:
        impactLevel = "Impact Inconnu";
    }
  
    // Définition de la variable classColor en fonction de score
    let classColor;
    switch (score) {
      case 0:
        classColor = "green";
        break;
      case 1:
        classColor = "yellow";
        break;
      case 2:
        classColor = "orange";
        break;
      case 3:
        classColor = "red";
        break;
      default:
        classColor = "unknown";
    }









    return (
        <div onClick={onClick} className={selectBg+" step-detail-subcontainer"}>
            <div className="logo-container">
                <img className="step-detail-icon" src={iconSource}></img>
            </div>
            <div className="detail-subtitle">{title}</div>
            <div className={classColor + "-impact impact-detail-container"}>
                <div className="detail-result">{score}</div>
            </div>
            <div className={classColor + "-color impact-detail-comment"}>
                {impactLevel}
            </div>
        </div>
        )
}

const ResultsDetailActivity = ({ data }) => {

  const [dataResult, setDataResult] = useState(data);

  const [clickedStatus, setClickedStatus] = useState(
    {
    'quantity_supply-chain':false,
    'pollution_supply-chain':false,
    'quantity_direct-operation':false,
    'pollution_direct-operation':false,
    'quantity_product-use':false,
    'pollution_product-use':false,
    }
  );
  const [explanation, setExplanation] = useState(undefined)

  function handleDetailClick(identifier) {
    console.log('click');
    if (clickedStatus[identifier]) {
        const newClickedStatus = {};
        Object.keys(clickedStatus).forEach(key => {
          newClickedStatus[key] = false;
        });
        setExplanation(undefined)
        setClickedStatus(newClickedStatus)
    } else {
        const newClickedStatus = {};
        Object.keys(clickedStatus).forEach(key => {
          newClickedStatus[key] = false;
        });
        newClickedStatus[identifier]=true;
        setExplanation(dataResult["comment_"+identifier])
        setClickedStatus(newClickedStatus)
    }
  }

  function urlify(text) {
    if (text==undefined) {
      text = ""
    }
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex)
       .map(part => {
          if(part.match(urlRegex)) {
             return <a href={part}>source</a>;
          }
          return part;
       });
  }

  const handleClick = () => {
    window.open('https://waterwise.fr/#contact', '_blank');
  };


  const handleClickMeet = () => {
    window.open('https://calendly.com/water-wise', '_blank');
  };




  return (
    <div className="information-card">
      <div className="header">
        <h2>{data.Industry} - {data.Activity}</h2>
      </div>
      <div className="global-score-container">
        <div className="global-score-main-text">Votre activité est classée comme {data.global_criticity} concernant ses risques et impacts sur les ressources en eau.</div>
        <div className="global-score-rating">Votre note de criticité concernant les ressources en eau est de {data.global_score} (sur une échelle de 18).</div>
        <div className="click-invit-section">
        <img className="click-icon" src="/icon/click.png"></img>
          <div className="click-invit-text">Cliquez sur les différents impacts pour en savoir plus.</div>
        </div>
        

      </div>
      <div className="value-chain-container">
        <div className="step-container supply-chain">
            <img className="step-icon" src="/icon/un-camion.png"></img>
            <h3>Chaîne d'approvisionnement</h3>
            <div className="step-detail-container">
                <StepDetailSubContainer onClick={()=>{handleDetailClick("pollution_supply-chain")}} dataResult={dataResult} step={"supply-chain"} type={"pollution"} isClicked={clickedStatus['pollution_supply-chain']}></StepDetailSubContainer>
                <StepDetailSubContainer onClick={() => {handleDetailClick("quantity_supply-chain")}} dataResult={dataResult} step={"supply-chain"} type={"quantity"} isClicked={clickedStatus['quantity_supply-chain']}></StepDetailSubContainer>
            </div>
            {/* Afficher les chiffres et les icônes ici */}
        </div>
        <div className="step-container direct-usage">
            <img className="step-icon" src="/icon/industrie.png"></img>
            <h3>Utilisation directe</h3>
            <div className="step-detail-container">
                <StepDetailSubContainer onClick={() => {handleDetailClick("pollution_direct-operation")}} dataResult={dataResult} step={"direct-operation"} type={"pollution"}  isClicked={clickedStatus['pollution_direct-operation']}></StepDetailSubContainer>
                <StepDetailSubContainer onClick={() => {handleDetailClick("quantity_direct-operation")}} dataResult={dataResult} step={"direct-operation"} type={"quantity"}  isClicked={clickedStatus['quantity_direct-operation']}></StepDetailSubContainer>
            </div>
        </div>
        <div className="step-container product-usage">
            <img className="step-icon" src="/icon/paquets.png"></img>
            <h3>Utilisation du produit</h3>
            <div className="step-detail-container">
                <StepDetailSubContainer onClick={() => {handleDetailClick("pollution_product-use")}} dataResult={dataResult} step={"product-use"} type={"pollution"}  isClicked={clickedStatus['pollution_product-use']}></StepDetailSubContainer>
                <StepDetailSubContainer onClick={() => {handleDetailClick("quantity_product-use")}} dataResult={dataResult} step={"product-use"} type={"quantity"}  isClicked={clickedStatus['quantity_product-use']}></StepDetailSubContainer>
            </div>
        </div>
      </div>
      <div className="explanation-container" style={{whiteSpace: "pre-wrap"}}>{urlify(explanation)}</div>

      <div className="meeting-container">
        <img className="meet-icon" src="/icon/discussion.png"></img>
        <div className="text-meeting">Prenez un rendez-vous gratuit de 30 minutes avec un de nos consultants pour décrypter vos résultats ensemble.</div>
        <button className="custom-button" onClick={handleClickMeet}>Prendre RDV</button>
      </div>

      <button className="custom-button" onClick={handleClick}>Découvrir nos offres</button>

    </div>
  );
};

export default ResultsDetailActivity;
