import React, { Fragment, useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import SurveyComponent from './components/SurveyComponent';
import ResultsComponent from './components/ResultsComponent';

// import {
//   createBrowserRouter,
//   RouterProvider,
//   useNavigate,
//   useParams,
// } from "react-router-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";


const App = () => {
  // Définir l'état local pour stocker les réponses
  const [responses, setResponses] = useState(null);
  const [isAnswer, setIsAnswer] = useState(false);
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/data-fr.json'); // Chemin vers votre fichier JSON dans le dossier "public"
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setJsonData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Callback pour mettre à jour les réponses
  const updateResponses = (newResponses) => {
    setResponses(newResponses);
    setIsAnswer(true);
    console.log(responses)
  };

  return (
    <Fragment>
      {!isAnswer ? ( 
      <SurveyComponent onUpdateResponses={updateResponses} />) 
      : (<ResultsComponent responses={responses} jsonData={jsonData} />)}
       
    </Fragment>
  );
};

// const ResultsPage = () => {
//   return (
//     <div>
//       <ResultsComponent />
//     </div>
//   );
// };


// const SurveyPage = () => {
//   return (
//     <div>
//       <SurveyComponent />
//     </div>
//   );
// };


// const router = createBrowserRouter([
//   {
//     path: "/results",
//     element: <ResultsPage/>,
//   },
//   {
//     path: "/survey",
//     element: <SurveyPage/>,
//   },
// ])

// function App() {
//   return <RouterProvider router={router} />;
// }





export default App;


