// Questionnaire.js
import React from "react";
import * as Survey from "survey-react";
import { useNavigate } from "react-router-dom";
import "survey-react/modern.css";

Survey.StylesManager.applyTheme("modern");

const SurveyComponent = ({onUpdateResponses}) => {

  const handleCompletion = (sender) => {
    const responses = sender.data; // Récupérer les réponses
    onUpdateResponses(responses); // Mettre à jour les réponses dans le composant parent
  };

  const json = {
    title: "Évaluer les risques et les impacts de votre activité sur les ressources en eau",
    pages: [
      {
        title: "Secteur",
        questions: [
          {
            type: "dropdown",
            name: "industry",
            title: "Sélectionnez votre secteur",
            isRequired: true,
            choices: ['Vêtements', 'Biotechnologie, soins de santé et pharmaceutique', 'Alimentation, boissons et agriculture', 'Les combustibles fossiles', 'Hospitalité', 'Infrastructure', 'Organismes internationaux', 'Fabrication', 'Matériaux', "Génération d'énergie", 'Vente au détail', 'Services', 'Services de transport']
          }
        ]
      },
      {
        title: "Type d'activité",
        questions: [
          {
            type: "dropdown",
            name: "activity_group",
            title: "Sélectionnez le groupe d'activité",
            isRequired: true,
            choicesByUrl: {
              url: "data-fr/{industry}_activity.json", // URL pour charger dynamiquement les options du sous-secteur en fonction du secteur sélectionné
              valueName: "value",
              titleName: "text"
            }
          }
        ]
      },
      {
        title: "Activités",
        questions: [
          {
            type: "dropdown",
            name: "activity",
            title: "Sélectionnez votre activité principale",
            isRequired: true,
            choicesByUrl: {
              url: "data-fr/{industry}_{activity_group}_sub_activity.json", // URL pour charger dynamiquement les options du sous-secteur en fonction du secteur sélectionné
              valueName: "value",
              titleName: "text"
            }
          }
        ]
      },
      // {
      //   title: "Actions concernant l'eau",
      //   questions: [
      //     {
      //       type: "radiogroup",
      //       name: "actions",
      //       title: "Avez-vous mis en place des actions concernant l'eau ?",
      //       isRequired: true,
      //       choices: ["Oui", "Non"]
      //     }
      //   ]
      // }
    ]
  };

  const survey = new Survey.Model(json);
  survey.locale = "fr";


  return <Survey.Survey model={survey} onComplete={handleCompletion}/>;
};

export default SurveyComponent;
