import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ResultsDetailActivity from "./ResultsDetailActivity";

const ResultsComponent = ({responses, jsonData}) => {
  


  function findJsonByCriteria(dataList, response) {
    return dataList.find(item => 
      item.Industry === response.industry &&
      item.Activity_Group === response.activity_group &&
      item.Activity === response.activity
    );
  }


  console.log(responses)
  return (
    <div>
      <div className="header-page">
        <h1 className="result-title">Votre impact sur l'eau</h1>
        <img className="logo_ww" src="/logo512.png"></img>
      </div>
      <ResultsDetailActivity data={findJsonByCriteria(jsonData, responses) }/>
    </div>
  );
};

export default ResultsComponent;
